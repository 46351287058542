import { useMemo } from 'react';
import PropTypes from 'prop-types';

import ModalWorkflowSimpleView from 'components/common/ModalWorkflowSimpleView';
import InviteOthersGraphic from '../../svg/graphic-invite-others.svg';

const CampaignPostJoinInviteDonorsModal = ({
  isCaptain,
  isReturningCaptain,
  progressCurrent,
  progressTotal,
  onContinue,
  onSkip,
}) => {
  const title = useMemo(() => {
    if (isReturningCaptain) return 'Get your team back together';
    if (isCaptain) return 'Start assembling your fundraising team';

    return 'Invite friends and family to donate';
  }, [isCaptain, isReturningCaptain]);

  const message = useMemo(() => {
    if (isReturningCaptain) {
      return (
        <p>
          View and select your previous teammates and send them a personalized invite to get them
          back on your team.
        </p>
      );
    }
    if (isCaptain) {
      return (
        <p>
          Fundraising is more fun with a group. Think about a few close friends who are up for the
          challenge and ask them to join your team!
        </p>
      );
    }
    return (
      <p>
        A quick email or text to 3-5 close friends or family members will help you raise 5x more
        than posting to social media alone
      </p>
    );
  }, [isCaptain, isReturningCaptain]);

  return (
    <ModalWorkflowSimpleView
      graphic={InviteOthersGraphic}
      title={title}
      message={message}
      progressCurrent={progressCurrent}
      progressTotal={progressTotal}
      continueLabel={`Invite ${isCaptain ? 'members' : 'donors'}`}
      onContinue={onContinue}
      skipLabel="Skip for now"
      onSkip={onSkip}
    />
  );
};

CampaignPostJoinInviteDonorsModal.propTypes = {
  isCaptain: PropTypes.bool,
  isReturningCaptain: PropTypes.bool,
  progressCurrent: PropTypes.number.isRequired,
  progressTotal: PropTypes.number.isRequired,
  onContinue: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
};

CampaignPostJoinInviteDonorsModal.defaultProps = {
  isCaptain: false,
  isReturningCaptain: false,
};

export default CampaignPostJoinInviteDonorsModal;
