import PropTypes from 'prop-types';

import ModalWorkflowSimpleView from 'components/common/ModalWorkflowSimpleView';
import LaunchGraphic from '../../svg/graphic-launch.svg';

const CampaignPostJoinRegistrationSuccessModal = ({
  isCaptain,
  progressCurrent,
  progressTotal,
  onContinue,
}) => {
  const message = isCaptain ? (
    <p>
      Welcome to your team dashboard where you can invite new members, track your team&apos;s
      progress, and send messages to members.
    </p>
  ) : (
    <p>
      Welcome to your fundraising page where you can track your fundraising progress, share your
      page, and connect with the community
    </p>
  );
  return (
    <ModalWorkflowSimpleView
      graphic={LaunchGraphic}
      title="Ready for takeoff!"
      message={message}
      progressCurrent={progressCurrent}
      progressTotal={progressTotal}
      continueLabel="Next"
      onContinue={onContinue}
    />
  );
};

CampaignPostJoinRegistrationSuccessModal.propTypes = {
  isCaptain: PropTypes.bool,
  progressCurrent: PropTypes.number.isRequired,
  progressTotal: PropTypes.number.isRequired,
  onContinue: PropTypes.func.isRequired,
};

CampaignPostJoinRegistrationSuccessModal.defaultProps = {
  isCaptain: false,
};

export default CampaignPostJoinRegistrationSuccessModal;
