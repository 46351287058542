import { ReactNode, ElementType } from 'react';
import cx from 'classnames';

import Button from 'components/common/Button';
import ProgressIndicator from 'components/common/ProgressIndicator';

type ModalWorkflowSimpleViewProps = {
  title?: ReactNode;
  message?: ReactNode;
  graphic?: ElementType;
  continueLabel?: ReactNode;
  skipLabel?: ReactNode;
  progressCurrent?: number;
  progressTotal?: number;
  onContinue: () => void;
  onSkip?: () => void;
  className?: string;
};

const ModalWorkflowSimpleView = ({
  title = null,
  message = null,
  graphic: Graphic = null,
  progressCurrent = null,
  progressTotal = null,
  continueLabel = 'Continue',
  skipLabel = 'Skip',
  onSkip = null,
  className = '',
  onContinue,
}: ModalWorkflowSimpleViewProps) => (
  <div className={cx('lg:pt-8', className)}>
    {Graphic && <Graphic className="mx-auto" />}

    {(!!title || !!message) && (
      <div
        className={cx('text-center', {
          'mt-8': !!Graphic,
          'pt-0.5': !Graphic,
        })}
      >
        {title && <h1 className="text-gray-800 font-medium text-2xl">{title}</h1>}
        {message && <div className={cx('text-gray-700', { 'mt-2': !!title })}>{message}</div>}
      </div>
    )}

    {progressTotal != null && (
      <div className="mt-8 flex justify-center">
        <ProgressIndicator current={progressCurrent} total={progressTotal} />
      </div>
    )}

    <div className="mt-8 grid grid-cols-1 gap-y-2">
      <Button
        as="button"
        type="button"
        color="primary"
        radius="full"
        onClick={onContinue}
        className="font-medium w-full"
      >
        {continueLabel}
      </Button>
      {onSkip && (
        <Button
          as="button"
          type="button"
          color="none"
          onClick={onSkip}
          className="font-medium w-full text-gray-600"
        >
          {skipLabel}
        </Button>
      )}
    </div>
  </div>
);

export default ModalWorkflowSimpleView;
