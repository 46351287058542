import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import config from 'config';
import { useGlobal } from 'context/Global';
import Button from 'components/common/Button';
import ProgressIndicator from 'components/common/ProgressIndicator';
import FormTextEditor from 'components/form/FormTextEditor';

const FUNDRAISER_STORY_FIELDS = gql`
  fragment FundraiserStoryFields on Fundraiser {
    id
    story
    campaign {
      id
      fundraiserDefaultStory
    }
  }
`;

const TEAM_STORY_FIELDS = gql`
  fragment TeamStoryFields on Team {
    id
    story
    campaign {
      id
      teamDefaultStory
    }
  }
`;

const GET_FUNDRAISER_STORY = gql`
  ${FUNDRAISER_STORY_FIELDS}
  query GetFundraiserStory($id: String!) {
    findFundraisers(id: $id) {
      ...FundraiserStoryFields
    }
  }
`;

const GET_TEAM_STORY = gql`
  ${TEAM_STORY_FIELDS}
  query GetTeamStory($id: String!) {
    findTeams(id: $id) {
      ...TeamStoryFields
    }
  }
`;

const UPDATE_FUNDRAISER_STORY = gql`
  ${FUNDRAISER_STORY_FIELDS}
  mutation UpdateFundraiserStory($data: FundraiserInput!) {
    updateFundraiser(Fundraiser: $data) {
      ...FundraiserStoryFields
    }
  }
`;

const UPDATE_TEAM_STORY = gql`
  ${TEAM_STORY_FIELDS}
  mutation UpdateTeamStory($data: TeamInput!) {
    updateTeam(Team: $data) {
      ...TeamStoryFields
    }
  }
`;

const CampaignPostJoinStoryModal = ({
  isCaptain,
  entityId,
  progressCurrent,
  progressTotal,
  onContinue,
}) => {
  const { addToast } = useGlobal();

  const [story, setStory] = useState(null);

  const queries = {
    query: isCaptain ? GET_TEAM_STORY : GET_FUNDRAISER_STORY,
    mutation: isCaptain ? UPDATE_TEAM_STORY : UPDATE_FUNDRAISER_STORY,
    queryName: isCaptain ? 'findTeams' : 'findFundraisers',
    defaultStory: isCaptain ? 'teamDefaultStory' : 'fundraiserDefaultStory',
  };

  useQuery(queries.query, {
    variables: { id: entityId },
    onCompleted: (data) => {
      setStory(
        data?.[queries.queryName]?.[0].story ??
          data?.[queries.queryName]?.[0].campaign?.[queries.defaultStory]
      );
    },
  });

  const [updateEntity, { loading }] = useMutation(queries.mutation);

  const onSubmit = useCallback(async () => {
    try {
      await updateEntity({
        variables: {
          data: {
            id: entityId,
            story,
          },
        },
      });

      onContinue();
    } catch (err) {
      if (config('/debug')) console.error(err);
      addToast({
        id: `${isCaptain ? 'team' : 'fundraiser'}:story`,
        type: 'error',
        duration: 'long',
        message: 'There was an error saving your changes. Please try again.',
      });
    }
  }, [isCaptain, updateEntity, entityId, story, onContinue, addToast]);

  return (
    <div>
      <div className="text-center">
        <h1 className="pt-0.5 text-gray-800 font-medium text-2xl">
          Tell your {isCaptain ? "team's" : ''} story
        </h1>
        <p className="mt-3 text-gray-700">
          Tell your donors about {isCaptain ? 'your team' : 'yourself'} and why you are fundraising
          for this cause.
        </p>
      </div>

      <div className="mt-4">
        <FormTextEditor value={story} onChange={setStory} />
      </div>

      <div className="mt-8 flex justify-center">
        <ProgressIndicator current={progressCurrent} total={progressTotal} />
      </div>

      <div className="mt-8 grid grid-cols-1 gap-y-2">
        <Button
          as="button"
          type="button"
          color="primary"
          radius="full"
          disabled={loading}
          onClick={onSubmit}
          className="font-medium w-full"
        >
          {loading ? 'Saving...' : 'Next'}
        </Button>
      </div>
    </div>
  );
};

CampaignPostJoinStoryModal.propTypes = {
  isCaptain: PropTypes.bool,
  entityId: PropTypes.string.isRequired,
  progressCurrent: PropTypes.number.isRequired,
  progressTotal: PropTypes.number.isRequired,
  onContinue: PropTypes.func.isRequired,
};

CampaignPostJoinStoryModal.defaultProps = {
  isCaptain: false,
};

export default CampaignPostJoinStoryModal;
