import PropTypes from 'prop-types';

import ModalWorkflowSimpleView from 'components/common/ModalWorkflowSimpleView';
import CommunityGraphic from '../../svg/graphic-community.svg';

const CampaignPostJoinCommunityModal = ({ progressCurrent, progressTotal, onContinue }) => (
  <ModalWorkflowSimpleView
    graphic={CommunityGraphic}
    title="Connect with the Community"
    message={
      <p>
        Let your supporters know what this campaign means to you by sharing your story, or simply
        browse the community feed and get inspired
      </p>
    }
    progressCurrent={progressCurrent}
    progressTotal={progressTotal}
    continueLabel="Check it out"
    onContinue={onContinue}
  />
);

CampaignPostJoinCommunityModal.propTypes = {
  progressCurrent: PropTypes.number.isRequired,
  progressTotal: PropTypes.number.isRequired,
  onContinue: PropTypes.func.isRequired,
};

CampaignPostJoinCommunityModal.defaultProps = {};

export default CampaignPostJoinCommunityModal;
