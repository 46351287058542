import { times } from 'lodash';
import cn from 'classnames';

type ProgressIndicatorProps = {
  current: number;
  total: number;
};

const ProgressIndicator = ({ current, total }: ProgressIndicatorProps) => (
  <div className="flex items-center gap-x-3">
    {times(total, (i) => (
      <div
        key={i}
        className={cn('w-2.5 h-2.5 rounded-full transition-color duration-200', {
          'bg-gray-800': current === i + 1,
          'bg-gray-400': current !== i + 1,
        })}
      />
    ))}
  </div>
);

export default ProgressIndicator;
